"use client";

import { Login as LoginBase } from "@bigspring/core-components";
import { useEffect, useState } from "react";
import { useSegment } from "@shared/Segment";
import {
  getCompanyProfileId,
  recoverPreviousUrl,
  storeWorkspace,
} from "@utils/auth";
import { parseGraphQLError } from "@utils/parseError";
import { useCookies } from "react-cookie";
import {
  AuthenticationSource,
  useAuthChannelLazyQuery,
  useAuthInitMutation,
  useAuthInitV2Mutation,
  useAuthVerifyMutation,
  useGoogleAuthVerifyMutation,
  useLearningPlanAssignmentMutation,
} from "@gql/generated/graphql";
import { typewriterTrack } from "@lib/events";
import { ApolloError, useApolloClient } from "@apollo/client";
import { useCustomRouter } from "@shared/CustomRouter";
import { AvailableRoutes } from "@config/routes";
import { useRouter } from "next/navigation";

export function Login() {
  const { replace: nextReplace } = useRouter();
  const { pathname, replace, query } = useCustomRouter();
  const segment = useSegment();
  const apolloClient = useApolloClient();
  const [, setCookie, removeCookie] = useCookies(["clientSso"]);

  const [assignLearningPlan] = useLearningPlanAssignmentMutation();

  const isLpSignup = query.get("lp_token");
  let lp: string;
  let company: string;
  if (isLpSignup) {
    const decoded = atob(isLpSignup as string);
    const params = new URLSearchParams(decoded);
    lp = params.get("lp") as string;
    company = params.get("c") as string;
  }

  const [authInit] = useAuthInitMutation();
  const [authInitv2] = useAuthInitV2Mutation();

  const [authVerify] = useAuthVerifyMutation();
  const [googleAuthVerifyFn] = useGoogleAuthVerifyMutation();
  const [authChannel] = useAuthChannelLazyQuery();

  const [authInitId, setAuthInitId] = useState("");

  // TODO: Remove hard-coded URL check and only use feature flag
  // const [ssoEnabled] = useState(() =>
  //   typeof window === 'undefined'
  //     ? false
  //     : !window.location.hostname.startsWith('app.bigspring.io')
  // ) // hasFeature(FeatureType.SSO)

  useEffect(() => {
    void segment.page("Login", { pathname });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signUpVerification = (initId: string) => {
    setAuthInitId(initId);
    localStorage.removeItem("authInitId");
  };

  const onSendVerificationCode = async (profileIdentifier: string) => {
    try {
      if (isLpSignup) {
        const results = await authInitv2({
          variables: {
            profileIdentifier,
            companyId: company,
          },
        });

        if (!results?.data?.authInitV2) {
          throw new Error("Unable to init authentication");
        }

        setAuthInitId(results.data.authInitV2);
      } else {
        const results = await authInit({
          variables: {
            profileIdentifier,
            workspaceId: getCompanyProfileId() ?? undefined,
          },
        });

        if (!results?.data?.authInit) {
          throw new Error("Unable to init authentication");
        }

        setAuthInitId(results.data.authInit);
      }
    } catch (e) {
      if (e instanceof ApolloError) {
        throw parseGraphQLError(e.graphQLErrors[0]);
      }

      throw e;
    }
  };

  /**
   * @param verificationCode
   */
  const onSubmitVerificationCode = async (verificationCode: string) => {
    try {
      const results = await authVerify({
        variables: {
          authInitId,
          code: verificationCode,
        },
      });

      const authData = results?.data?.authVerify;

      if (!authData) {
        throw {
          code: "TOKEN_GENERATION_FAIL_CODE",
          message: "Unexpected error while authenticating",
        };
      }

      // assign LP to user
      if (isLpSignup) {
        await assignLearningPlan({
          variables: {
            profileExternalId: authData.profileExternalId,
            companyExternalId: company,
            learningPlanExternalId: lp,
          },
          context: {
            credentials: "omit",
          },
        });
      }
      //

      storeWorkspace(authData.companyExternalId, authData.companyProfileId);

      await apolloClient.resetStore();

      const previousPath = recoverPreviousUrl();
      if (previousPath) {
        nextReplace(
          `${previousPath.pathname}${previousPath.search}${previousPath.hash}`
        );
      } else {
        await replace("home");
      }
    } catch (e) {
      if (e instanceof ApolloError) {
        throw parseGraphQLError(e.graphQLErrors[0]);
      }

      throw e;
    }
  };

  /**
   *
   * @param profileId
   */
  const onSsoSignin = async (profileId: string) => {
    try {
      const { data } = await authChannel({
        variables: { profileIdentifier: profileId },
      });
      const chType = data?.authChannel?.channelType;

      if (!chType || chType !== "SSO") {
        throw new Error(
          "SSO is not enabled for this account. Please request a login code."
        );
      }

      removeCookie("clientSso", {
        path: "/",
        domain: window.location.hostname,
      });

      const path = data?.authChannel?.ssoChannel?.idpProxyPath;
      const url = `${process.env.NEXT_PUBLIC_BACKEND_URL}${path}`;

      const originPath: AvailableRoutes = "home";
      const redirectTo: AvailableRoutes = "ssoRedirect";
      const cookieVal = JSON.stringify({
        profileId,
        originPath,
        redirectTo,
      });

      const expAtInMil = new Date().getTime() + 120000;
      const expAt = new Date(expAtInMil);
      setCookie("clientSso", cookieVal, {
        path: "/",
        domain: window.location.hostname,
        expires: expAt,
      });

      nextReplace(url);
    } catch (e) {
      if (e instanceof ApolloError) {
        throw parseGraphQLError(e.graphQLErrors[0]);
      }

      throw e;
    }
  };

  const onGAuthSuccess = async (credential: string) => {
    let authEmail: string | undefined = undefined;
    try {
      const parts = credential.split(".");
      if (parts.length !== 3)
        throw new Error("Invalid Google Auth JWT format !");
      const payload = JSON.parse(atob(parts[1]));
      authEmail = payload?.email;

      const gauthResponse = await googleAuthVerifyFn({
        variables: {
          idToken: credential,
          source: AuthenticationSource.App,
        },
      });

      const authData = gauthResponse?.data?.googleAuthVerify;
      if (!authData?.accessToken || !authData?.refreshToken) {
        throw {
          code: "TOKEN_GENERATION_FAIL_CODE",
          message: "Unexpected error while authenticating",
        };
      }

      await apolloClient.resetStore();

      storeWorkspace(authData.companyExternalId, authData.companyProfileId);

      const photoUpdateStatus =
        gauthResponse?.data?.googleAuthVerify.socialPhotoUpdateStatus;
      const photoUpdateErr =
        gauthResponse?.data?.googleAuthVerify.socialPhotoUpdateErrorMessage ??
        "";

      if (photoUpdateStatus === "success") {
        typewriterTrack("socialPhotoUpdated", {
          email: authEmail,
          source: "pwa",
        });
      } else if (photoUpdateStatus === "error") {
        typewriterTrack("socialPhotoUpdationFailed", {
          email: authEmail,
          source: "pwa",
          cause: photoUpdateErr,
        });
      }

      const previousPath = recoverPreviousUrl();
      if (previousPath) {
        nextReplace(
          `${previousPath.pathname}${previousPath.search}${previousPath.hash}`
        );
      } else {
        await replace("home");
      }
    } catch (e) {
      typewriterTrack("googleSigninAuthVerifyFailure", {
        email: authEmail,
        source: "pwa",
        cause: (e as Error).message,
      });

      if (e instanceof ApolloError) {
        throw parseGraphQLError(e.graphQLErrors[0]);
      }

      throw e;
    }
  };

  return (
    <LoginBase
      source="PWA"
      signUpVerification={signUpVerification}
      onSendVerificationCode={onSendVerificationCode}
      onSubmitVerificationCode={onSubmitVerificationCode}
      isSsoEnabled={false}
      onSsoLogin={onSsoSignin}
      onGoogleAuthSuccess={onGAuthSuccess}
      segment={segment}
      googleApiKey={process.env.NEXT_PUBLIC_GOOGLE_API_KEY as string}
      googleMapsApiKey={process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY as string}
    />
  );
}
